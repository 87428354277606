

function Contact() {
  return (
    <div>
      Contact info coming soon
    </div>
  );
}

export default Contact;