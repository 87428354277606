import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout } from '../redux/siteWideSettingsSlice';
import { useNavigate } from 'react-router-dom';

import '../style/Header.css';

function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userAccount = useSelector((state) => state.siteWideSettings?.userAccount);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = async () => {
    await dispatch(logout);
    navigate('/');
  };

  return (
    <header>
      <div className="business-title">
        <h1>Calton LLC</h1>
      </div>
      <nav>
        {userAccount?.isLoggedIn ? (
          <ul>
            <li className={`user-info ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
              <span className="user-name">
                {userAccount.FirstName} {userAccount.LastName}
              </span>
              <span className="account-type">
                {userAccount.AccountType}
              </span>
              <ul className={`dropdown-content ${isMenuOpen ? 'show' : ''}`}>
                <>
                  <li><Link to="/profile">Profile</Link></li>
                  <li><Link to="/" onClick={handleLogout}>Logout</Link></li>
                </>
              </ul>
            </li>
          </ul>
        ) : (
          <div><Link to="/login">Login</Link></div>
        )}
      </nav>
    </header>
  );
}

export default Header;
