import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home';
import Contact from '../pages/Contact';
import Dashboard from '../pages/Dashboard';
import Properties from '../pages/Properties';
import Tenants from '../pages/Tenants';
import Documents from '../pages/Documents';
import Payments from '../pages/Payments';
import ServiceRequest from '../pages/ServiceRequest';
import Login from '../pages/Login';

function SiteRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path='/contact' element={<Contact />} />
      <Route path='/dashboard' element={<Dashboard />} />
      <Route path='/properties' element={<Properties />} />
      <Route path='/tenants' element={<Tenants />} />
      <Route path='/documents' element={<Documents />} />
      <Route path='/payments' element={<Payments />} />
      <Route path='/servicerequest' element={<ServiceRequest />} />
      <Route path='/login' element={<Login />} />
    </Routes>
  );
}

export default SiteRoutes;