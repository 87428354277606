import React, { useState } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

function Properties() {
  const initialProperties = [
    { id: 1, name: 'Property A', address: '123 Main St', status: 'Available', rentCost: 1000, tenant: null },
    { id: 2, name: 'Property B', address: '456 Oak St', status: 'Rented', rentCost: 1200, tenant: 'John Doe' },
    { id: 3, name: 'Property C', address: '789 Pine St', status: 'Available', rentCost: 900, tenant: null },
    { id: 4, name: 'Property D', address: '101 Elm St', status: 'Rented', rentCost: 1100, tenant: 'Jane Smith' },
    { id: 5, name: 'Property E', address: '202 Maple St', status: 'Available', rentCost: 950, tenant: null },
    { id: 6, name: 'Property F', address: '303 Cedar St', status: 'Available', rentCost: 1050, tenant: null },
    { id: 7, name: 'Property G', address: '404 Birch St', status: 'Rented', rentCost: 1150, tenant: 'Bob Johnson' },
    { id: 8, name: 'Property H', address: '505 Walnut St', status: 'Available', rentCost: 980, tenant: null },
    { id: 9, name: 'Property I', address: '606 Oakwood St', status: 'Rented', rentCost: 1250, tenant: 'Alice Brown' },
    { id: 10, name: 'Property J', address: '707 Pineview St', status: 'Available', rentCost: 870, tenant: null },
    { id: 11, name: 'Property K', address: '808 Cedarwood St', status: 'Available', rentCost: 1070, tenant: null },
    { id: 12, name: 'Property L', address: '909 Birchwood St', status: 'Rented', rentCost: 1180, tenant: 'Charlie Green' },
    { id: 13, name: 'Property M', address: '1010 Maplewood St', status: 'Available', rentCost: 930, tenant: null },
    { id: 14, name: 'Property N', address: '1111 Walnutwood St', status: 'Rented', rentCost: 1220, tenant: 'Eva White' },
    { id: 15, name: 'Property O', address: '1212 Pinegrove St', status: 'Available', rentCost: 890, tenant: null },
  ];


  const [properties, setProperties] = useState(initialProperties);
  const [selectedProperty, setSelectedProperty] = useState(null);

  const handlePropertyClick = (property) => {
    setSelectedProperty(property);
  };

  const closeModal = () => {
    setSelectedProperty(null);
  };

  return (
    <div className="container mt-4">
      <div className="row row-cols-1 row-cols-md-3 g-4">
        {properties.map((property) => (
          <div key={property.id} className="col">
            <div className="card" onClick={() => handlePropertyClick(property)}>
              <div className="card-body">
                <h3 className="card-title">{property.name}</h3>
                <p className="card-text">{property.address}</p>
                <p className="card-text">Status: {property.status}</p>
                <p className="card-text">Rent Cost: ${property.rentCost}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Modal
        isOpen={selectedProperty !== null}
        onRequestClose={closeModal}
        contentLabel="Property Details"
      >
        <div>
          <h2>{selectedProperty?.name}</h2>
          <p>{selectedProperty?.address}</p>
          <p>Status: {selectedProperty?.status}</p>
          <p>Rent Cost: ${selectedProperty?.rentCost}</p>
          {selectedProperty?.tenant && <p>Tenant: {selectedProperty?.tenant}</p>}
          <button onClick={closeModal}>Close</button>
        </div>
      </Modal>
    </div>
  );
}

export default Properties;
