import { createSlice } from "@reduxjs/toolkit";

const users = [
  { username: 'john', password: 'password', UserID: '1', AccountType: 'Tenant', FirstName: 'John', LastName: 'Doe', Email: 'john@example.com' },
  { username: 'lidia', password: 'password', UserID: '2', AccountType: 'Tenant', FirstName: 'Lidia', LastName: 'Paula', Email: 'lidia@example.com' },
];

export const siteWideSettingsSlice = createSlice({
  name: 'SiteWideSettings',
  initialState: {
    userAccount: {
      isLoggedIn: false,
      UserID: '',
      AccountType: '',
      FirstName: '',
      LastName: '',
      Email: ''
    }
  },
  reducers: {
    setUserAccount: (state, action) => {
      state.userAccount = action.payload;
    },
    setUserAccountLogOut: (state, action) => {
      state.userAccount.isLoggedIn = action.payload;
    },
  }
});

export const { setUserAccount, setUserAccountLogOut } = siteWideSettingsSlice.actions;

export const login = (dispatch, username, password) => {
  const authenticatedUser = users.find(user => user.username === username && user.password === password);

  if (authenticatedUser) {
    dispatch(setUserAccount({
      isLoggedIn: true,
      UserID: authenticatedUser.UserID,
      AccountType: authenticatedUser.AccountType,
      FirstName: authenticatedUser.FirstName,
      LastName: authenticatedUser.LastName,
      Email: authenticatedUser.Email
    }));
  } else {
    dispatch(setUserAccountLogOut(false));
  }
};

export const logout = (dispatch) => {
  dispatch(setUserAccountLogOut(false));
};

export default siteWideSettingsSlice.reducer;
