import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faAddressCard,
  faChartBar,
  faBuilding,
  faUsers,
  faFileAlt,
  faMoneyBillAlt,
  faTools
} from '@fortawesome/free-solid-svg-icons';

const LeftNavMenu = () => {
  const [isExpanded, setIsExpanded] = useState(true);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const isLoggedIn = useSelector((state) => state?.siteWideSettings?.userAccount?.isLoggedIn);

  return (
    <div className={`left-nav-menu ${isExpanded ? 'expanded' : 'shrunken'}`}>
      <button className="toggle-button" onClick={handleToggle}>
        ☰
      </button>
      {!isLoggedIn ? (
        <>
          <Link to="/">
            <div className='left-nav-icon'>
              <FontAwesomeIcon icon={faHome} />
            </div>
            {isExpanded && 'Home'}
          </Link>
          <Link to="/contact">
            <div className='left-nav-icon'>
              <FontAwesomeIcon icon={faAddressCard} />
            </div>
            {isExpanded && 'Contact'}
          </Link>
        </>
      ) : (
        <>
          <Link to='/dashboard'>
            <div className='left-nav-icon'>
              <FontAwesomeIcon icon={faChartBar} />
            </div>
            {isExpanded && 'Dashboard'}
          </Link>
          <Link to='/properties'>
            <div className='left-nav-icon'>
              <FontAwesomeIcon icon={faBuilding} />
            </div>
            {isExpanded && 'Properties'}
          </Link>
          <Link to='/tenants'>
            <div className='left-nav-icon'>
              <FontAwesomeIcon icon={faUsers} />
            </div>
            {isExpanded && 'Tenants'}
          </Link>
          <Link to='/documents'>
            <div className='left-nav-icon'>
              <FontAwesomeIcon icon={faFileAlt} />
            </div>
            {isExpanded && 'Documents'}
          </Link>
          <Link to='/payments'>
            <div className='left-nav-icon'>
              <FontAwesomeIcon icon={faMoneyBillAlt} />
            </div>
            {isExpanded && 'Payments'}
          </Link>
          <Link to='/servicerequest'>
            <div className='left-nav-icon'>
              <FontAwesomeIcon icon={faTools} />
            </div>
            {isExpanded && 'Service Request'}
          </Link>
        </>
      )}
    </div>
  );
};

export default LeftNavMenu;
