import React, { useState } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

function Tenants() {
  const tenantsData = [
    { id: 1, name: 'Tenant 1', role: 'User', status: 'Active' },
    { id: 2, name: 'Tenant 2', role: 'Admin', status: 'Pending' },
    { id: 3, name: 'Tenant 3', role: 'User', status: 'Active' },
    { id: 4, name: 'Tenant 4', role: 'User', status: 'Pending' },
    { id: 5, name: 'Tenant 5', role: 'User', status: 'Active' },
    { id: 6, name: 'Tenant 6', role: 'User', status: 'Pending' },
    { id: 7, name: 'Tenant 7', role: 'User', status: 'Active' },
    { id: 8, name: 'Tenant 8', role: 'Admin', status: 'Pending' },
    { id: 9, name: 'Tenant 9', role: 'User', status: 'Active' },
  ];

  const [selectedTenant, setSelectedTenant] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = (tenant) => {
    setSelectedTenant(tenant);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedTenant(null);
    setModalIsOpen(false);
  };

  return (
    <div>
      <h2>Tenants List</h2>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Role</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {tenantsData.map(tenant => (
            <tr key={tenant.id}>
              <td>{tenant.name}</td>
              <td>{tenant.role}</td>
              <td>{tenant.status}</td>
              <td>
                <button className='btn btn-primary btn-sm' onClick={() => openModal(tenant)}>View Details</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Tenant Details Modal"
      >
        {selectedTenant && (
          <div>
            <h2>Tenant Details</h2>
            <p>Name: {selectedTenant.name}</p>
            <p>Role: {selectedTenant.role}</p>
            <p>Status: {selectedTenant.status}</p>
            <button className='btn btn-primary btn-sm' onClick={closeModal}>Close</button>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default Tenants;
