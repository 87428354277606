

function Home() {
    return (
        <div>
            {/* welcome */}
            {/* followed wizard of oz leading to the homes */}
            {/* https://www.thelocklyn.com/ */}
        </div>
    );
}

export default Home;