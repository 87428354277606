import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from '../components/Header';
import SiteRoutes from '../components/SiteRoutes';
import Footer from '../components/Footer';
import LeftNavMenu from '../components/Nav/LeftNavMenu';
import '../style/App.css';

function App() {
  const [isLeftNavOpen, setIsLeftNavOpen] = useState(true);

  const toggleLeftNav = () => {
    setIsLeftNavOpen(!isLeftNavOpen);
  };

  return (
    <Router>
      <div className="app-container">
        {/* Include the LeftNavMenu component */}
        <LeftNavMenu isOpen={isLeftNavOpen} />
        <main className="main-container">
          <Header onToggleLeftNav={toggleLeftNav} />
          <SiteRoutes />
          <Footer className="Footer" />
        </main>
      </div>
    </Router>
  );
}

export default App;
